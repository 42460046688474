import React, { useState } from "react";
import DatabaseIcon from "../assets/svgs/DatabaseIcon";
import BoltIcon from "../assets/svgs/BoltIcon";
import CloudIcon from "../assets/svgs/CloudIcon";
import cx from "classnames";
import Sparkle2 from "../assets/svgs/Sparkle2";
import {
  FEATURE_SHOWCASE_AI,
  FEATURE_SHOWCASE_DATA_SOURCES,
  FEATURE_SHOWCASE_SELF_HOSTED,
  FEATURE_SHOWCASE_STRONG_SQL,
} from "@/static-urls";
const Feature = ({ feature }) => {
  const { name, Icon, title, content, img, reversed, imgDimensions, imgAlt } =
    feature;
  const [titleRef, setTitleRef] = useState(0);
  return (
    <div
      className={cx(
        " flex justify-center items-center gap-x-[150px] max-sm:gap-x-0 pb-[20rem] max-sm:pb-[80px] max-sm:flex-col",
        {
          "flex-row-reverse": !reversed,
        }
      )}
    >
      <div
        className={cx(" flex items-center basis-1/2 max-sm:basis-auto", {
          "justify-end ": reversed,
        })}
      >
        <div
          className={cx(
            "text-[#ededed] antialiased flex flex-col  justify-center items-start"
          )}
        >
          <div className=" inline-flex border border-[#535353] rounded-lg px-2 py-1 items-center gap-x-2  bg-[rgba(83,83,83,.4)] font-medium text-[14px] font-inter">
            <Icon size={18} className="stroke-[#ededed]" />
            <h3>{name}</h3>
          </div>
          <div className=" font-geoGraph font-medium text-[30px] my-5">
            <h2
              ref={setTitleRef}
              className=" whitespace-nowrap max-sm:whitespace-normal"
            >
              {title}
            </h2>
          </div>
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: content }}
              className={cx(
                " font-medium text-[15px] max-sm:text-[14px] font-inter text-[#a9a9a9] leading-relaxed"
              )}
            ></p>
          </div>
        </div>
      </div>

      <div
        className={cx(
          " basis-1/2 max-sm:basis-auto flex items-center max-sm:mt-[80px]",
          {
            "justify-end": !reversed,
            "justify-start": reversed,
          }
        )}
      >
        <img
          src={img}
          alt={imgAlt}
          className={cx(
            `w-[${imgDimensions.width}px] h-[${imgDimensions.height}px] max-sm:w-full max-sm:h-auto`
          )}
          title={imgAlt}
        />
      </div>
    </div>
  );
};

const FeatureShowcase = () => {
  const features = [
    {
      name: "Data sources",
      Icon: DatabaseIcon,
      title: "Connect your data sources",
      content:
        "Support for various data sources like PostgreSQL, MySQL. File formats CSV, Excel, Parquet and remotely available data stored in your AWS S3, GCP Cloud storage.",
      img: FEATURE_SHOWCASE_DATA_SOURCES,
      imgAlt: "flamingos-feature-data-sources",
      imgDimensions: { height: 191, width: 364 },
      reversed: true,
    },
    {
      name: "SQL",
      Icon: BoltIcon,
      title: "Strong support for SQL",
      content:
        "Use your existing knowledge of SQL and perform complex analysis on your data to get the critical insights needed for your business.",
      img: FEATURE_SHOWCASE_STRONG_SQL,
      imgAlt: "flamingos-feature-SQL",
      imgDimensions: { height: 455, width: 379 },
      reversed: false,
    },
    {
      name: "Serverless / Self-hosted",
      Icon: CloudIcon,
      title: "Serverless cloud or Self-Hosted",
      content:
        " &quot;Cloud&quot; option manages the data infrastructure for you so you can focus on analysing data while we handle all the complexity from storage, compute to query executions. <br><br>OR <br><br>&quot;Self-Host&quot; Flamingos on your own infrastructure without data ever leaving your servers.",
      img: FEATURE_SHOWCASE_SELF_HOSTED,
      imgAlt: "flamingos-feature-serverless-selfhosted",
      imgDimensions: { height: 247, width: 373 },
      reversed: true,
    },

    {
      name: "AI",
      Icon: Sparkle2,
      title: "Real time synthetic data generation",
      content:
        "One of the core goal for Flamingos AI is to enable data analysis just as a human would.<br><br> To achieve that we not only need powerful LLMs but also a robust mechanism in order to provide the most accurate AI generations that not only sounds human but gets work done as well.",
      img: FEATURE_SHOWCASE_AI,
      imgAlt: "flamingos-feature-AI",
      imgDimensions: { height: 236, width: 345 },
      reversed: false,
    },
  ];
  return (
    <React.Fragment>
      {features.map((item, index) => (
        <Feature key={index} feature={item} />
      ))}
    </React.Fragment>
  );
};

export default FeatureShowcase;
