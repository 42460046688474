import Link from "next/link";
import React from "react";
import { routesURL } from "../urls";

const Outro = () => {
  return (
    <div className=" flex justify-between items-center pb-[160px] max-sm:pb-[80px] max-sm:flex-col max-sm:gap-y-[70px]">
      <h3 className="font-geoGraph font-medium antialiased text-[30px] max-sm:text-[26px] text-[#ededed]">
        Analyse your data today.
        <br /> Take bolder decisions for tomorrow.
      </h3>
      <div className=" flex items-center gap-x-4">
        <Link href={routesURL.login} target="__blank">
          <button className=" flex items-center justify-center font-inter font-regular text-[15px] text-[#000000] bg-[#f9f9f9] rounded-[10px] h-[42px] w-[123px] hover:scale-105 transition-transform duration-200">
            Start now
          </button>
        </Link>
        <Link href={"https://cal.com/flamingos/30min"} target="__blank">
          <button className="flex items-center justify-center font-inter font-regular text-[14px] text-[#f9f9f9] bg-[rgba(112,110,106,.3)] rounded-[10px] h-[42px] w-[140px] hover:scale-105 transition-transform duration-200">
            Talk to Founder
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Outro;
