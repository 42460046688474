import React from "react";
import cx from "classnames";
const Case = ({ item }) => {
  const { title, content, titlSize } = item;
  return (
    <div
      className={cx(" rounded-[20px] py-[24px] ", {
        "bg-[rgba(112,110,106,.1)] px-[22px] max-sm:h-[260px]": title,
      })}
    >
      {title && (
        <h3
          className={cx(" text-[#7DFE9A] font-geoGraph  font-medium", {
            "text-[20px]": !titlSize,
            [titlSize]: titlSize,
          })}
        >
          {title}
        </h3>
      )}
      <p
        className={cx(
          " text-[#a9a9a9] text-[13px] max-sm:text-[16px] font-regular leading-relaxed",
          {
            " h-full flex items-center justify-center text-[20px] text-[#ededed]":
              !title,
            "mt-10 ": title,
          }
        )}
      >
        {content}
      </p>
    </div>
  );
};
const UseCases = () => {
  const useCases = [
    {
      title: "Exploratory Analysis",
      content:
        "Analyse and investigate data sets, get into the depth, summarise their main characteristics and build visualisation to get insights.",
    },
    {
      title: "Descriptive Statistics",
      content:
        "Get a broader understanding of the dataset by measuring central tendencies like Mean, Median and Mode or finding for variability Standard Deviation, Variance, Minimum and Maximums, Kurtosis and Skewness. ",
    },
    {
      title: "Data Modelling/ Transformations",
      titlSize: "text-[18px]",
      content:
        "Pre-process and clean the raw dataset and make necessary transformations before using it for analysis.",
    },
    {
      title: "Data Visualisations",
      content:
        "Representation of the data in interactive charts and graphs with filtering options.",
    },
    {
      title: "KPI dashboards",
      content:
        "Representation of the data in interactive charts and graphs with filtering options.",
    },
    {
      content: "Discover and build more use cases for your data. Join now. ",
    },
  ];
  return (
    <React.Fragment>
      <h3 className=" font-geoGraph text-[35px] max-sm:text-[30px] font-medium text-[#ededed] text-center">
        Build anything with your data
      </h3>
      <p className="font-geoGraph text-[15px] max-sm:text-[16px] font-regular text-[#a9a9a9] text-center">
        Your data we provide a playground for you to explore use cases that
        unlocks the unknown
      </p>
      <div className=" grid grid-cols-[332px_332px] grid-rows-[repeat(3,224px)] max-sm:grid-cols-1 max-sm:grid-rows-1 gap-x-[50px] gap-y-[60px] place-content-center mt-[80px] pb-[100px]">
        {useCases.map((item, index) => (
          <Case key={index} item={item} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default UseCases;
