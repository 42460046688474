import React, { useState } from "react";
import cx from "classnames";
import PlusIcon from "../assets/svgs/PlusIcon";
const QA = ({ item }) => {
  const { q, a } = item;
  const [show, setShow] = useState(false);
  const showHandle = () => setShow(true);
  const hideHandle = () => setShow(false);

  return (
    <div className=" my-[15px] select-none">
      <div
        className=" flex justify-between items-center cursor-pointer gap-x-9"
        onClick={!show ? showHandle : hideHandle}
      >
        <h5 className=" text-[16px]  font-medium text-[#ededed] font-inter antialiased ">
          {q}
        </h5>
        <PlusIcon
          size={22}
          className={cx(
            " cursor-pointer transition-transform duration-200 stroke-[#ededed] shrink-0",
            {
              " rotate-45": show,
              "rotate-0": !show,
            }
          )}
        />
      </div>

      <div
        className={cx(
          " mt-6 text-[14px] transition-[grid-template-rows] antialiased duration-200 ease-in-out overflow-hidden grid",
          {
            " grid-rows-[0fr]": !show,
            " grid-rows-[1fr]": show,
          }
        )}
      >
        <p
          className=" overflow-hidden leading-relaxed text-[#a9a9a9]"
          dangerouslySetInnerHTML={{ __html: a }}
        ></p>
      </div>
    </div>
  );
};
const FAQ = () => {
  const faq = [
    {
      q: "How is Flamingos different from 1000 other different Data tools?",
      a: "Flamingos provides a fast and simple way for Data Analysis using AI and SQL. Serverless design makes it possible for you to upload large datasets and run your most complex queries without managing any infrastructure and it works for you right out of the box with NO INFRASTRUCTURE SETUP, NO COMPLEX DATA PIPELINES and NO EXPENSIVE CLOUD COSTS!!! Pricing is very affordable for your daily analysis workloads. We also provide meaningful AI-powered features to make it easy for you to operate and move fast with your business decisions that relies on your data and insights. Try it once and discover how easy and powerful it is to do data analysis using Flamingos or book a demo call and we will show you it's powers!",
    },
    {
      q: "How does AI-powered Data Analytics work and how will it improve my workflow?",
      a: "Flamingos uses advanced AI models from <a href='https://www.openai.com' target='_blank' style='color: pink; text-decoration: underline;'>OpenAI</a>, <a href='https://www.anthropic.com/' target='_blank' style='color: pink; text-decoration: underline;'>Anthropic</a> to power our AI features. Enabling AI in the workflow increases the effectiveness of performing data analysis over your data without you googling for syntax or remembering complex queries we will save you time and money. We do this by converting your ideas or thoughts (text prompts) into SQL. This is then run using our engine which makes the workflow blazing fast and accurate — from executing your queries to getting results. Trust us we make this work brilliantly!",
    },
    {
      q: "How safe is my data and what is the privacy policy around my data?",
      a: "We believe in prioritising safety and security for our users data and it is of utmost importance for us therefore we have implemented strict measures to protect and safeguard your data. Read our privacy policy for information.",
    },
    {
      q: "What all Data source types or File formats are supported?",
      a: "Flamingos AI-powered Data Analytics currently supports Excel, CSV, <a href='https://en.wikipedia.org/wiki/Apache_Parquet' target='_blank' style='color: ; text-decoration: underline;'>Parquet</a> and Postgres DB, MySQL and remote querying of AWS S3 and GCP cloud storage. However, we are working on releasing more data source types on our platform in coming weeks. If you have a request for us to support specific data source type, send us your request at tausif@flamingos.dev",
    },
    {
      q: "Is Flamingos FREE? What is the pricing?",
      a: "Yes, we do have a FREE plan valid for first 30 days or until plan quota lasts for evert user. No credit card is required to use Flamingos while on our FREE plan. You can check our pricing page for detailed information on features and plans.",
    },
    {
      q: "Is Flamingos useful for enterprise use cases with self hosting options on our servers?",
      a: "Yes absolutely! Flamingos is capable of supporting enterprise use cases and we respect concerns about your data policies. We also provide custom features for enterprise users that also includes on-prem deployment and integration support, custom ETL/ELT workflows and more. Schedule a demo with us to know how Flamingos can be used according to your preferences. Book a demo call now!",
    },
    {
      q: "Does Flamingos support large datasets? I mean >100 million rows or GBs of data?",
      a: "Yes, it is robust enough to support querying on large datasets too. We have internally benchmarked on multi-millions rows of data and it runs smoothly. You can book a demo call with the Founder to help us understand your specific use case and data size.",
    },
    // {
    //   q: "",
    //   a: "Simply connect your data source and start querying! Flamingos brings to you, powerful, fast and easy AI data analytics with table views and visualisations. Flamingos helps you query your data sources with simple chat prompts or via dedicated Notebook based SQL query executions. Find and build your data use case with Flamingos today! ",
    // },
  ];
  return (
    <React.Fragment>
      <h4
        className=" font-geoGraph font-medium text-[30px] text-[#ededed] text-center max-sm:text-left max-sm:text-[26px]"
        id="FAQ"
      >
        Frequently asked questions
      </h4>
      <div className=" mt-[60px] pb-[120px]">
        {faq.map((item, index) => (
          <QA key={index} item={item} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default FAQ;
