import React from "react";
import cx from "classnames";
const DatabaseIcon = ({ size = 20, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cx("icon icon-tabler icon-tabler-database", className)}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0" />
      <path d="M4 6v6a8 3 0 0 0 16 0v-6" />
      <path d="M4 12v6a8 3 0 0 0 16 0v-6" />
    </svg>
  );
};

export default DatabaseIcon;
