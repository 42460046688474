import React from "react";
const Principle = ({ principle }) => {
  const { text, desc } = principle;
  return (
    <div className=" flex  gap-x-[30px] my-[30px] font-inter text-[15px] antialiased">
      <div className=" basis-1/4 font-medium text-[#EDEDED]">{text}</div>
      <div className=" basis-3/4 text-[#a9a9a9]">{desc}</div>
    </div>
  );
};
const StrongPrinciples = () => {
  const principles = [
    {
      text: "Embedded execution engine",
      desc: "Built in a way to support the most compute intensive queries over large datasets with a focus on speed and large data transfers for analysis.",
    },
    {
      text: "Secure and Safe",
      desc: "Dedicated storage with all data and connection credentials encrypted at rest. All Data transfers happens over HTTPS endpoints.",
    },
    {
      text: "Engineered for scale",
      desc: "Engineered to run smoothly for teams of different sizes be it early stage startups or enterprises.",
    },
  ];
  return (
    <React.Fragment>
      <h3 className=" font-geoGraph text-[30px] font-medium text-[#EDEDED] max-sm:hidden">
        Built on strong principles
      </h3>
      <h6 className=" font-inter text-[15px] font-normal text-[#a9a9a9]  mt-8 leading-relaxed  max-sm:hidden">
        Behind the simplicity of using Flamingos there is engineering
        <br />
        that makes it a powerful, secure and blazing fast analytics software.
      </h6>
      <div className=" py-[60px]  max-sm:hidden">
        <div className=" border-y border-y-[#1e1e1e] w-3/4 py-[30px] ">
          {principles.map((principle, i) => (
            <Principle key={i} principle={principle} />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StrongPrinciples;
