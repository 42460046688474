import { NextSeo } from "next-seo";
import useTitle from "@/components/hooks/useTitle";
import Main from "@/components/landing-page/Main";
import Header from "@/components/landing-page/Header";
import BodyContent from "@/components/landing-page/BodyContent";
import Section from "@/components/landing-page/Section";
import BuiltForDataAnalysis from "@/components/landing-page/BuiltForDataAnalysis";
import FeatureShowcase from "@/components/landing-page/FeatureShowcase";
import UseCases from "@/components/landing-page/UseCases";
import FAQ from "@/components/landing-page/FAQ";
import Outro from "@/components/landing-page/Outro";
import Footer from "@/components/landing-page/Footer";
import StrongPrinciples from "@/components/landing-page/StrongPrinciples";
import Head from "next/head";
import useResponsive from "@/components/hooks/useResponsive";

export default function Home() {
  useTitle("Flamingos | Analyse Data Using AI");
  const { currentScreenWidth } = useResponsive();
  return (
    <>
      <Head>
        <meta
          property="og:image"
          content="https://flamingos.dev/open_graph_image.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://flamingos.dev" />
        <meta
          name="twitter:image"
          content="https://flamingos.dev/open_graph_image.png"
        />
        <meta name="twitter:image:type" content="website" />
        <meta name="twitter:image:width" content="1200" />
        <meta name="twitter:image:height" content="630" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Flamingos | Analyse Data Using AI"
        />
        <meta
          name="twitter:description"
          content="Flamingos is a high performance serverless data platform that enables you to manage and analyse
            your data in an easy to use, blazing fast and AI powered way"
        />
      </Head>
      <NextSeo
        title="Flamingos | Analyse Data Using AI"
        description="Simplify your data analytics with AI."
        openGraph={{
          url: "https://flamingos.dev",
          title: "Flamingos | Analyse Data Using AI",
          description:"Flamingos is a high performance serverless data platform that enables you to manage and analyse your data in an easy to use, blazing fast and AI powered way.",
          images: [
            {
              url: "https://flamingos.dev/open_graph_image.png",
              width: 1200,
              height: 630,
              alt: "Flamingos Open Graph Image",
            },
          ],
          site_name: "",
        }}
      />

      <Main>
        <Header>
          <BodyContent>
            <Section>
              <BuiltForDataAnalysis />
            </Section>
            <Section>
              <FeatureShowcase />
            </Section>
            <Section>
              <UseCases />
            </Section>
            <Section>
              <StrongPrinciples />
            </Section>
            <Section>
              <FAQ />
            </Section>
            <Section>
              <Outro />
            </Section>
          </BodyContent>
        </Header>
        <Footer />
      </Main>
    </>
  );
}
