import React from "react";
import BoltIcon from "../assets/svgs/BoltIcon";
import DatabaseIcon from "../assets/svgs/DatabaseIcon";
import Sparkle from "../assets/svgs/Sparkle";
import cx from "classnames";
import NotebookIcon from "../assets/svgs/NotebookIcon";
import SwipeIcon from "../assets/svgs/SwipeIcon";
import { CHAT_DEMO_2, NOTEBOOK_DEMO_1_FAST } from "@/static-urls";

const FeatureWithImage = ({ reversed = false, img, video, title, body }) => {
  return (
    <div
      className={cx(
        "flex gap-x-[80px] max-sm:gap-y-[50px] items-center justify-between h-[500px] max-sm:h-auto  max-sm:flex-col-reverse",
        {
          "flex-row-reverse": reversed,
        }
      )}
    >
      <div className={cx(` basis-1/2  max-sm:w-full`)}>
        {video ? (
          <video
            src={video}
            title={title}
            className="h-[360px] max-sm:h-auto w-[700] object-fill rounded-lg"
            autoPlay
            muted
            loop
            playsInline
            aria-label={title}
          />
        ) : (
          <img
            src={img}
            alt={title}
            className="h-full w-full object-cover"
            title={title}
            aria-label={title}
          />
        )}
      </div>
      <div
        className={cx("basis-1/2 max-sm:basis-auto flex items-center", {
          "justify-end": reversed,
        })}
      >
        <p className="text-[#ededed] text-[24px] max-sm:text-[18px] font-medium antialiased w-3/4 max-sm:w-full">
          {body}
        </p>
      </div>
    </div>
  );
};

const Card2 = ({ title, Icon, content }) => (
  <div className=" py-[25px] px-[30px] font-inter h-[229px] max-sm:h-auto w-[362px] max-sm:w-full bg-[rgba(112,110,106,.1)] rounded-[10px] max-sm:shrink-0 max-sm:snap-center">
    <div className=" inline-flex items-center gap-x-1 text-[#ededed] border border-[#535353] rounded-lg px-2.5 py-1.5 bg-[rgba(112,110,106,.2)]">
      <Icon size={20} className="stroke-[#ededed]" />
      <h3>{title}</h3>
    </div>
    <div className=" text-[#a9a9a9] mt-10 text-[15px] max-sm:text-[16px] leading-relaxed">
      <p>{content}</p>
    </div>
  </div>
);

const Card = ({ title, Icon, body }) => (
  <div
    className="h-[260px] max-sm:h-auto w-[278px] p-[20px_35px] rounded-[20px] font-inter relative overflow-hidden bg-cover bg-center shadow-lg hover:shadow-xl transition-shadow duration-300 max-sm:shrink-0 max-sm:snap-center"
    style={{
      backgroundImage: "url(/images/landing-page/main_card_box.svg)",
      opacity: 1,
    }}
  >
    <div className="flex gap-x-3 items-center text-[#ededed] text-[18px] max-sm:text-[16px] font-bold whitespace-nowrap antialiased">
      <Icon size={22} className="stroke-[#ededed]" />
      <h3>{title}</h3>
    </div>
    <p className="mt-10 text-[14px] max-sm:text-[14px] leading-relaxed text-[#a9a9a9]">
      {body}
    </p>
  </div>
);

const BuiltForDataAnalysis = () => {
  const featureCards = [
    {
      title: "Scalable and Fast",
      icon: BoltIcon,
      body: "No infrastructure setup, No data pipelines and No manual re-sizing of compute and storage. Query execution happens in the cloud which makes it easy and blazing fast for varying workloads.",
    },
    {
      title: "Multi data sources",
      icon: DatabaseIcon,
      body: "Connect various types of data sources and file formats for analysis. Also native support for directly remote querying of data stored in your AWS S3 or GCP cloud storage.",
    },
    {
      title: "AI native experience",
      icon: Sparkle,
      body: "Levraging powerful models like OpenAI GPT, Anthropic Sonnet 3.5 (more models coming soon) to provide you with the most accurate AI response that helps achieve complex tasks.",
    },
  ];
  return (
    <React.Fragment>
      <h2
        className=" text-center text-[#EDEDED] text-[35px] max-sm:text-[30px] font-geoGraph font-medium antialiased pt-[80px]"
        id="features"
      >
        Built for powerful data analysis
      </h2>
      <div
        className=" flex justify-center gap-x-[7rem] max-sm:gap-x-10 max-sm:justify-start mt-24 no-scrollbar  max-sm:overflow-x-scroll"
        style={{ scrollSnapType: "x mandatory", scrollSnapStop: "always" }}
      >
        {featureCards.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            Icon={card.icon}
            body={card.body}
          />
        ))}
      </div>
      <div className="py-[150px] max-sm:py-[80px]">
        <FeatureWithImage
          reversed
          body="Chat with your data, generate a SQL and execute in the cloud to get insights that drives your business forward."
          video={CHAT_DEMO_2}
          title="flamingos-chat-with-your-data"
        />
      </div>
      <div
        className=" flex max-sm:flex-col items-center justify-center gap-x-[80px]  max-sm:gap-y-10 max-sm:justify-start mt-24 no-scrollbar"
        style={{ scrollSnapType: "x mandatory", scrollSnapStop: "always" }}
      >
        <Card2
          title={"Notebook"}
          Icon={NotebookIcon}
          content={
            "Notebook provides a dedicated interface for analysis with a built-in SQL editor. Designed to perform the best analysis and plot the data across different visualisations. "
          }
        />
        <Card2
          title={"Chat"}
          Icon={SwipeIcon}
          content={
            "Chat with our intelligent system to get super fast insights about the data. Get instant and accurate results and move fast. All of this with absolutely zero SQL know-how."
          }
        />
      </div>
      <div className="py-[150px] max-sm:py-[80px]">
        <FeatureWithImage
          body={
            "Notebook provides you with a dedicated interface to run the most powerful queries for your data analysis."
          }
          video={NOTEBOOK_DEMO_1_FAST}
          title="Flamingos Notebook"
        />
      </div>
    </React.Fragment>
  );
};

export default BuiltForDataAnalysis;
